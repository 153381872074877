/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NextRouter } from 'next/router';

import FotMobLogo from 'components/Predictor/Components/FotMobLogo';
import { SponsoredByWithoutDisclaimer, SponsoredByDisclaimer } from 'components/Predictor/Components/Betting/SponsoredBy';
import useUser from 'lib/useUser';
import { useShowSponsoredBy } from 'lib/odds';
import { useResolvePredictorTheme } from 'lib/usePredictorTheme';
import { usePredictorUrl } from 'lib/usePredictorUrl';
import { useTournamentId } from 'lib/useTournamentId';
import { useConfig } from 'lib/useConfig';
import * as st from './predictorStartPage.styles';
import { StartPageLogoutButton } from './predictorStartPage.styles';

interface MainSectionProps {
  readonly router: NextRouter;
  readonly setShowLoginPopup: (value: boolean) => void;
  readonly showStartButton: boolean;
}

const MainSection: React.FC<MainSectionProps> = ({
  router, setShowLoginPopup, showStartButton,
}) => {
  const {
    loggedIn, readOnlyUser, tournamentName,
  } = useUser();
  const { cellphoneImg, startPageText } = useResolvePredictorTheme();
  const { t } = useTranslation();
  const showSponsoredBy = useShowSponsoredBy();
  const stepOneUrl = usePredictorUrl('step/1');

  function handleRedirect() {
    router.push(stepOneUrl,
      undefined,
      { shallow: true });
  }

  const id = useTournamentId();
  const config = useConfig();

  const tournamentConfig = config.games?.find((game) => game.id === id);
  const startPagePredictorCompetition = tournamentConfig.mustNotBeNamed
    ? `Predict the ${tournamentConfig?.title}`
    : t('startpage_predictor_competition');

  const startNow = t('startpage_start_now');
  const alreadyRegistered = t('startpage_already_registered');
  const logIn = t('startpage_log_in');

  return (
    <st.PSPMainSection>
      <st.PSPMainPageWrapper>
        <st.PSPMainPageContent hasImage={cellphoneImg}>
          <st.PSPHeader>
            <st.PSPTopContainer>
              <st.PSPLogoContainer>
                <FotMobLogo color={startPageText} />
              </st.PSPLogoContainer>
              {loggedIn && <StartPageLogoutButton />}
            </st.PSPTopContainer>
            <st.PSPHeaderTitleBold>
              {tournamentConfig.mustNotBeNamed
                ? 'Tournament Predictor'
                : (tournamentConfig?.title ?? tournamentName)}
            </st.PSPHeaderTitleBold>
            <st.PSPHeaderSubTitle>
              {startPagePredictorCompetition}
            </st.PSPHeaderSubTitle>
            {showSponsoredBy && <SponsoredByWithoutDisclaimer horizontal />}
          </st.PSPHeader>
          <st.PSPActionSectionContainer>
            {showStartButton && (
              <st.PSPActionButton onClick={handleRedirect}>
                {startNow}
              </st.PSPActionButton>
            )}
            {(!loggedIn || readOnlyUser) && (
              <st.PSPLogInContainer>
                <st.PSPLogInText>
                  {alreadyRegistered}
                </st.PSPLogInText>
                <st.PSPLogInButton
                  onClick={() => setShowLoginPopup(true)}
                >
                  {logIn}
                </st.PSPLogInButton>
              </st.PSPLogInContainer>
            )}
            {showSponsoredBy && <SponsoredByDisclaimer horizontal />}
          </st.PSPActionSectionContainer>
        </st.PSPMainPageContent>
        <st.PSPMainPageImage>
          {cellphoneImg && (
          <img src={cellphoneImg} alt="cellphone" />
          ) }
        </st.PSPMainPageImage>
      </st.PSPMainPageWrapper>
    </st.PSPMainSection>
  );
};

export default MainSection;
