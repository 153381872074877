import React, { useState } from 'react';
import { useRouter } from 'next/router';
import useIsTournamentStarted from 'lib/useIsTournamentStarted';
import { useLoginModal } from 'components/Predictor/Components/PredictorContainer/LoginModalProvider';
import { useHasTournamentData } from 'lib/utils';
import { useRedirectToPrediction } from 'lib/useRedirectToPrediction';
import PredictorSpinner from 'components/Spinner/predictorSpinner';
import * as st from './predictorStartPage.styles';
import PSPScreenInfo from '../../Components/ScreenInfo/index';
import StartPageScreenPointsInfo from '../../Components/ScreenInfo/StartPageInfo/StartPageScreenPointsInfo';
import StartPageScreenPrizesInfo from '../../Components/ScreenInfo/StartPageInfo/StartPageScreenPrizesInfo';
import FollowInTheApp from './FollowInTheApp';
import Footer from './Footer';
import MainSection from './MainSection';
import AboutTheCompetition from './AboutTheCompetition';

const PredictorStartPage: React.FC = () => {
  const router = useRouter();
  const isStarted = useIsTournamentStarted();
  const hasTournamentData = useHasTournamentData();
  const [showPointsInfo, setShowPointsInfo] = useState(false);
  const [showPrizeInfo, setShowPrizeInfo] = useState(false);
  const setShowLoginPopup = useLoginModal();

  const loading = useRedirectToPrediction();

  return (
    <PredictorSpinner show={loading}>
      <st.SPBGContainer>
        <MainSection
          router={router}
          setShowLoginPopup={setShowLoginPopup}
          showStartButton={!isStarted && hasTournamentData}
        />
        <st.PSPWrapper>
          <AboutTheCompetition
            setShowPointsInfo={setShowPointsInfo}
            setShowPrizeInfo={setShowPrizeInfo}
          />
          <FollowInTheApp />
        </st.PSPWrapper>
        <Footer />
      </st.SPBGContainer>
      <PSPScreenInfo isOpen={showPointsInfo} close={() => setShowPointsInfo(false)}>
        <StartPageScreenPointsInfo />
      </PSPScreenInfo>
      <PSPScreenInfo isOpen={showPrizeInfo} close={() => setShowPrizeInfo(false)}>
        <StartPageScreenPrizesInfo />
      </PSPScreenInfo>
    </PredictorSpinner>
  );
};

export default PredictorStartPage;
