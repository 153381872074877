export const FotMobAppIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
    <g transform="translate(-41 -445)">
      <rect data-name="Rectangle 5271" width="60" height="60" rx="14" transform="translate(41 445)" fill="#15985f" />
      <path
        d="M16.456 34.161v11.755H7.934v-7.151zM43.2 2.914v5.975H16.456v11.853L7.934 22.8V2.914A2.593 2.593 0 0 1 10.481.367h30.17A2.593 2.593 0 0 1 43.2 2.914zm-23.8 22.04-25.319 9.168v-5.583l25.608-6.328a9.364 9.364 0 0 0-.289 2.743zm.49 2.253a10.132 10.132 0 0 0 1.078 2.057L-5.919 42.057v-5.584zm7.543-8.522a5.79 5.79 0 0 1 5.779 5.779 5.779 5.779 0 0 1-11.559 0 5.79 5.79 0 0 1 5.779-5.779"
        transform="translate(46.943 451.633)"
        fill="#fff"
      />
    </g>
  </svg>
);
