import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { hasRoundOf16Stage } from 'lib/configUtils';
import * as st from '../screenInfo.styles';

const tablePoints = [
  '100 points for correctly placed team',
  ' 80 points for  1 place wrong',
  ' 68 points for  2 places wrong',
  ' 58 points for  3 places wrong',
  ' 50 points for  4 places wrong',
  ' 43 points for  5 places wrong',
  ' 37 points for  6 places wrong',
  ' 32 points for  7 places wrong',
  ' 27 points for  8 places wrong',
  ' 23 points for  9 places wrong',
  ' 19 points for 10 places wrong',
  ' 16 points for 11 places wrong',
  ' 13 points for 12 places wrong',
  ' 10 points for 13 places wrong',
  '  8 points for 14 places wrong',
  '  6 points for 15 places wrong',
  '  4 points for 16 places wrong',
  '  3 points for 17 places wrong',
  '  2 points for 18 places wrong',
  '  1 points for 19 places wrong',
];

const getPoints = (config, t) => ([
  t('pointspopup_1pts'),
  hasRoundOf16Stage(config) ? t('pointspopup_2pts') : undefined,
  t('pointspopup_3pts'),
  t('pointspopup_4pts'),
  !config?.noBronzeFinal ? t('pointspopup_5pts') : undefined,
  t('pointspopup_10pts'),
].filter(Boolean));

const StartPageScreenPointsInfo = () => {
  const config = useSelector((state) => state.server?.tournament ?? state.tournament);
  const { t } = useTranslation();

  const points = config.tablePrediction
    ? tablePoints
    : getPoints(config, t);

  return (
    <>
      <st.PSIHeader>{t('pointspopup_about_the_competition')}</st.PSIHeader>
      <st.PSIDescription>
        {t('pointspopup_points_are_calculated')}
      </st.PSIDescription>
      <st.PSIList>
        { points.map((item) => (<st.PSIListItem key={item}>{item}</st.PSIListItem>)) }
      </st.PSIList>
    </>
  );
};

StartPageScreenPointsInfo.propTypes = {};
StartPageScreenPointsInfo.defaultProps = {};

export default StartPageScreenPointsInfo;
