import React from 'react';

export const MoreIconSvg: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
    <g transform="translate(-416 -1161)">
      <circle data-name="Ellipse 479" cx="30" cy="30" r="30" transform="translate(416 1161)" fill="#ffffff" />
      <g transform="translate(-12504 -605)">
        <path data-name="Rectangle 4361" transform="translate(12935 1781)" fill="none" d="M0 0h30v30H0z" />
        <rect data-name="Rectangle 4393" width="20" height="2.5" rx="1.25" transform="translate(12940 1788.5)" fill="#15985f" />
        <rect data-name="Rectangle 4394" width="20" height="2.5" rx="1.25" transform="translate(12940 1795)" fill="#15985f" />
        <rect data-name="Rectangle 4395" width="20" height="2.5" rx="1.25" transform="translate(12940 1801.5)" fill="#15985f" />
      </g>
    </g>
  </svg>
);
