import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { getAllAlternateLangUrl } from 'helpers/getAlternateLangUrl';
import PredictorHead from 'components/Predictor/Components/Head';
import { GetServerSideProps } from 'next';
import { fetchTournamentConfigServerSide } from 'state/getServerSideProps';
import { getTournaments } from 'state/remoteCalls/predictorRemoteCalls';
import { useConfig } from 'lib/useConfig';
import { useTournamentId } from 'lib/useTournamentId';
import { cacheResponseForOneDay } from 'lib/cache';
import StartPage from '../../components/Predictor/Pages/StartPage';

const PredictorStartPage = () => {
  const { asPath } = useRouter();

  const config = useConfig();
  const id = useTournamentId();
  const gameConfig = config.games?.find((g) => g.id === id);

  return (
    <>
      <PredictorHead
        gameConfig={gameConfig}
      />
      <Head>
        {getAllAlternateLangUrl(asPath)}
      </Head>
      <StartPage />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { query, res } = context;
  const { game } = query;

  const config = await getTournaments();
  const games = Object.keys(config.tournamentId);
  if (!games.some((g) => g === game)) {
    res.statusCode = 404;
    res.end();
  }
  const serverSideProps = await fetchTournamentConfigServerSide(context);

  cacheResponseForOneDay(res);
  return serverSideProps;
};

export default PredictorStartPage;
