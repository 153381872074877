import React, { FC, ReactElement } from 'react';

const InfoIconSvg: FC = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <path data-name="Path 2386" d="M0 0h25v25H0z" fill="none" />
    <path data-name="Path 2387" d="M12.417 2a10.417 10.417 0 1 0 10.416 10.417A10.42 10.42 0 0 0 12.417 2zm0 15.625a1.045 1.045 0 0 1-1.042-1.042v-4.166a1.042 1.042 0 1 1 2.083 0v4.167a1.045 1.045 0 0 1-1.041 1.041zm1.042-8.333h-2.084V7.208h2.083z" transform="translate(.083 .083)" />
  </svg>
);

export default InfoIconSvg;
