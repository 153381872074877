import { useSelector } from 'react-redux';

export function htmlEntities(str) {
  return String(str)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');
}

// This needs to be updated/handles in some different way seems to be an issue with React parsing html codes
export function convertHTMLCodes(str) {
  return String(str).replace('/&rsquo;', "'");
}

/**
 * Method used to sanitize some HTML markup that we get from the api
 */
export function sanitizeHTMLStr(htmlStr) {
  const scriptCleaningExp = /(<script.*?<\/script>)/gi;
  // Remove potential script-injections into the article
  const cleanStr = htmlStr.replace(scriptCleaningExp, '');

  return cleanStr;
}

/**
 * Shortens a text and returns a shortened text with ... at the end
 *
 * @param text
 * @param maxLength
 * @returns The shortened version of the text
 */
export function shortenText(text, maxLength) {
  let ret = text;
  if (ret.length > maxLength) {
    ret = `${ret.substr(0, maxLength - 3)}...`;
  }
  return ret;
}

export function isDeviceDesktop() {
  if (typeof window !== 'undefined') {
    return window.matchMedia('(min-width: 820px)').matches;
  }
  return true;
}

/**
 * Checks to see if localStorage is enabled
 */
export function isLocalStorageEnabled() {
  try {
    localStorage.setItem('test', 'works');
    localStorage.removeItem('test');
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Detects if a device is iOS or not
 */
export function isiOS() {
  if (typeof navigator !== 'undefined') {
    return navigator.userAgent.match(/ipad|iphone/i);
  }
  return false;
}

/**
 * Copies text to clipboard
 */
export function copyToClipboard(text) {
  const textField = document.createElement('textArea');
  textField.value = text;
  document.body.appendChild(textField);

  if (isiOS()) {
    const range = document.createRange();

    textField.contenteditable = true;
    textField.readonly = false;
    range.selectNodeContents(textField);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    textField.setSelectionRange(0, 999999);
    // textField.select();
    document.execCommand('copy');
    document.body.removeChild(textField);
  } else {
    textField.select();
    document.execCommand('copy');
    document.body.removeChild(textField);
  }
}

/**
 * Detects if a device is Android or not
 */
export function isAndroid() {
  if (typeof navigator !== 'undefined') {
    return navigator.userAgent.toLowerCase().indexOf('android') > -1;
  }
  return false;
}

export const useHasTournamentData = () => {
  const hasData = useSelector((state) => state.tournament?.firstGame);

  return Boolean(hasData);
};
