import { getPredictorUrl } from 'components/Predictor/Pages/helpers';
import { useCallback } from 'react';
import { useConfig } from './useConfig';
import { useTournamentId } from './useTournamentId';

export const usePredictorUrlDefered = (): (x: string) => string => {
  const config = useConfig();
  const tournamentId = useTournamentId();

  return useCallback(
    (path = ''): string => getPredictorUrl(config, tournamentId, path),
    [config, tournamentId],
  );
};

export const usePredictorUrl = (path = ''): string => usePredictorUrlDefered()(path);
