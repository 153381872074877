/* eslint-disable @next/next/no-img-element */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FotMobAppIcon } from 'components/Predictor/Components/SVGs/FotMobAppIcon';
import { useResolvePredictorTheme } from 'lib/usePredictorTheme';
import { RightArrow2Svg } from '../../Components/SVGs/RightArrow2Svg';
import { MoreIconSvg } from '../../Components/SVGs/MoreIconSvg';
import {
  FollowInTheAppContainer,
  FollowInTheAppContent,
  FollowInTheAppTitle,
  FollowInTheAppDescription,
  IconContainerGrid,
  ImageContainer,
} from './predictorStartPage.styles';

const androidOrIOs = 'Android/iOS';

const FollowInTheApp: React.FC = () => {
  const { followInTheAppPhoneImage } = useResolvePredictorTheme();

  const { t } = useTranslation();
  const followInTheApp = t('follow_in_the_app');
  const followInTheAppDescription = t('follow_in_the_app_description');
  const moreTab = t('more_tab');
  return (
    <FollowInTheAppContainer>
      <FollowInTheAppContent>
        <FollowInTheAppTitle>{followInTheApp}</FollowInTheAppTitle>
        <FollowInTheAppDescription>
          <span>
            {followInTheAppDescription}
          </span>
        </FollowInTheAppDescription>
        <IconContainerGrid>
          <FotMobAppIcon />
          <RightArrow2Svg />
          <MoreIconSvg />
          <div>{androidOrIOs}</div>
          <div />
          <div>{moreTab}</div>
        </IconContainerGrid>
      </FollowInTheAppContent>
      <ImageContainer>
        <img src={followInTheAppPhoneImage} alt="more in the app" />
      </ImageContainer>
    </FollowInTheAppContainer>
  );
};

export default FollowInTheApp;
