import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoIconSvg from '../../Components/SVGs/InfoIconSvg';
import PrizesIconSvg from '../../Components/SVGs/PrizesIconSvg';
import * as st from './predictorStartPage.styles';

interface AboutTheCompetitionProps {
  setShowPointsInfo: React.Dispatch<React.SetStateAction<boolean>>;
  setShowPrizeInfo: React.Dispatch<React.SetStateAction<boolean>>;
}

const AboutTheCompetition: React.FC<AboutTheCompetitionProps> = ({
  setShowPointsInfo,
  setShowPrizeInfo,
}) => {
  const { t } = useTranslation();
  const showPrizes = false; // TODO: Remove this when we have a text for the Prizes component. Remember to update texts and translations.

  const aboutTheCompetition = t('pointspopup_about_the_competition');
  const pointsCalculation = t('myleaguespage_points_calculation');

  return (
    <st.PredictorStartPageAboutSection>
      <st.PSPAboutText>
        {aboutTheCompetition}
      </st.PSPAboutText>
      <st.PSPAboutButtons>
        <st.PSPInfoButton onClick={() => setShowPointsInfo(true)}>
          <InfoIconSvg />
          <span>{pointsCalculation}</span>
        </st.PSPInfoButton>
        {showPrizes && (
        <st.PSPInfoButton onClick={() => setShowPrizeInfo(true)}>
          <PrizesIconSvg />
          <span>Prizes</span>
        </st.PSPInfoButton>
        ) }
      </st.PSPAboutButtons>
    </st.PredictorStartPageAboutSection>
  );
};

export default AboutTheCompetition;
