import useUserPredictions from 'components/Predictor/Pages/WheelPage/useUserPredictions';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useUser from './useUser';

export const useRedirectToPrediction = (): boolean => {
  const [
    fetchingPredictions,
    userCompletedPredictionsError,
    userCompletedPredictions,
  ] = useUserPredictions();
  const { loggedIn } = useUser();
  const router = useRouter();
  const { game } = router.query as { game: string };

  useEffect(() => {
    if (!loggedIn) {
      return;
    }

    if (fetchingPredictions) {
      return;
    }

    if (userCompletedPredictionsError) {
      return;
    }

    if (!userCompletedPredictions?.groups?.length) {
      return;
    }

    router.replace(`/${game}/prediction/wheel`, undefined, { shallow: true });
  }, [fetchingPredictions, userCompletedPredictionsError, userCompletedPredictions]);

  return fetchingPredictions;
};
